import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Youtube = makeShortcode("Youtube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`L'histoire de la Consigne GreenGo !`}</p>
    <p>{`Du 1er lancement aux conseils de Yasmine pour se lancer dans l'entreprenariat,  en passant par des livraisons avec le scooter de Lucas... découvrez les débuts de La Consigne GreenGo !`}</p>
    <Youtube youtubeVideoId="VfwBh17bCEk" mdxType="Youtube"></Youtube>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      